body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.left-panel,
.right-panel {
  width: 250px;
  background-color: #f0f0f0;
  padding: 20px;
  overflow-y: auto;
}

.central-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.canvas {
  min-height: 300px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  position: relative;
}

.live-preview {
  height: 1200px;
  background-color: #f9f9f9;
  border-top: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-container {
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.preview-container > div {
  max-height: 100%;
  max-width: 100%;
}

.live-preview select {
  margin-bottom: 10px;
  padding: 5px;
  font-size: 14px;
}

.component-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.component-item {
  padding: 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: move;
  transition: background-color 0.3s;
}

.component-item:hover {
  background-color: #d0d0d0;
}

.component-item.dragging {
  opacity: 0.5;
}

.draggable-component {
  position: absolute;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: move;
}

.property-editor,
.style-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

input[type="text"],
input[type="color"],
input[type="number"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

h2,
h3 {
  margin-top: 0;
  margin-bottom: 15px;
}
